import React, { ChangeEvent } from "react"
import { TextField, Grid, InputAdornment } from "@material-ui/core"
import { Search } from "@material-ui/icons"
import { useTranslation } from "react-i18next"

interface Zone {
  id: any
  name: string
}
interface Genre {
  id: any
  name: string
  name_en: string
  name_ua: string
}

export const SearchAndZoneFilters = (props: {
  zones: Zone[]
  genres: Genre[]
  searchQuery: string
  selectedZoneId: string
  selectedGenreId: string
  onClickSearch: () => void
  onSearch: (searchQuery: string) => void
  onSelectZone: (zoneId: any) => void
  onSelectGenre: (genreId: any) => void
}) => {
  const { t, i18n } = useTranslation()
  return (
    <>
      <Grid item xs={12}>
        <TextField
          size="small"
          value={props.searchQuery}
          label={t("map.search")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          onClick={() => {
            props.onClickSearch()
          }}
          onChange={(e: ChangeEvent) => {
            props.onSearch(e.target.value)
          }}
          variant="filled"
          style={{
            width: "100%",
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          select
          size="small"
          label={t("map.genre")}
          style={{
            width: "100%",
          }}
          SelectProps={{
            native: true,
            value: props.selectedGenreId,
          }}
          variant="filled"
          onChange={(e: ChangeEvent) => {
            props.onSelectGenre(e.target.value)
          }}
        >
          <option value=""></option>
          {props.genres &&
            props.genres.map(({ id, name, name_en, name_ua }: Genre) => (
              <option key={id} value={id}>
                {i18n.language === "lt-LT"
                  ? name
                  : i18n.language === "en-US"
                  ? name_en
                  : name_ua}
              </option>
            ))}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          select
          size="small"
          label={t("map.zone")}
          style={{
            width: "100%",
          }}
          SelectProps={{
            native: true,
            value: props.selectedZoneId,
          }}
          placeholder="All zones"
          variant="filled"
          onChange={(e: ChangeEvent) => {
            props.onSelectZone(e.target.value)
          }}
        >
          <option value=""></option>
          {props.zones &&
            props.zones.map(({ id, name }) => {
              return (
                <option key={id} value={id}>
                  {name}
                </option>
              )
            })}
        </TextField>
      </Grid>
    </>
  )
}
