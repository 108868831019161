import React from "react"
import {
  Box,
  Button,
  Grid,
  Divider,
  Typography,
  Link,
  makeStyles,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Explore, Close } from "@material-ui/icons"

import { GMDYellow } from "components/logos/gmd-main-yellow"
import { Performance } from "./types"
import { decimalHourToSexagesimal } from "utilities"
import { Description } from "components/description"

const useArtistBarStyles = makeStyles(theme => {
  return {
    artistName: {
      fontSize: theme.typography.h6.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      whiteSpace: "nowrap",
    },
    outlinedButton: {
      fontWeight: theme.typography.fontWeightBold,
      borderColor: theme.palette.text.primary,
      height: "100%",
      width: "100%",
      lineHeight: 1.2,
      textTransform: "uppercase",
    },
  }
})

const ArtistBar = ({
  onClose,
  onShowMap,
}: {
  onClose: () => void
  onShowMap: () => void
}) => {
  const classes = useArtistBarStyles()
  const { t } = useTranslation()
  return (
    <Box p={2}>
      <Grid container spacing={2} justifyContent="space-around">
        <Grid item xs={6}>
          <Button
            onClick={onClose}
            size="large"
            className={classes.outlinedButton}
            endIcon={<Close />}
            variant="outlined"
          >
            {t("button.close")}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={onShowMap}
            size="large"
            className={classes.outlinedButton}
            endIcon={<Explore />}
            variant="outlined"
          >
            {t("button.map")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

const useInfoCardStyles = makeStyles(theme => ({
  contentContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    flexDirection: "row",
    flexWrap: "nowrap",
    [theme.breakpoints.down("md")]: {
      flexWrap: "initial",
      flexDirection: "column",
    },
  },
  textContainer: {
    overflowY: "hidden",
    justifyContent: "center",
    flexWrap: "nowrap",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
  },
  locationBarContainer: {
    position: "absolute",
    bottom: 0,
    right: 0,
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    zIndex: theme.zIndex.tooltip,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 0,
  },
  artistPopout: {
    height: "100%",
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: theme.palette.primary.main,
    backgroundPosition: "center",
    zIndex: theme.zIndex.tooltip,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "58.33%", // xs={7} in MUI to cover the map
    },
  },
  artistInfoContainer: {
    margin: "auto",
    overflow: "auto",
    flexWrap: "nowrap",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
  },
  imageContainer: {
    marginBottom: theme.spacing(2),
    justifyContent: "center",
    alignItems: "center",
  },
  artistNameContainer: {
    marginBottom: theme.spacing(2),
  },
  artistName: {
    fontWeight: theme.typography.fontWeightBold,
    textTransform: "capitalize",
  },
  genreName: {
    fontWeight: theme.typography.fontWeightBold,
    textTransform: "capitalize",
  },
  artistLink: {
    width: "90%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  artistImage: {
    borderRadius: "4px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: 200,
    width: 200,
  },
  infoLabel: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
  aboutTextContainer: {
    padding: theme.spacing(3),
    flexWrap: "nowrap",
  },
}))

export const ArtistInfoPopout = ({
  performance,
  onClose,
  onShowMap,
}: {
  performance: Performance
  onClose: () => void
  onShowMap: () => void
}) => {
  const {
    performer: { name, image, about, website, genre },
    location,
    hourFrom,
    hourTo,
  } = performance
  const time = `${decimalHourToSexagesimal(
    hourFrom
  )} - ${decimalHourToSexagesimal(hourTo)}`
  const { t, i18n } = useTranslation()
  const classes = useInfoCardStyles()
  return (
    <Box className={classes.artistPopout}>
      <Grid
        className={classes.artistInfoContainer}
        container
        direction="column"
        justifyContent="center"
      >
        <Grid
          container
          justifyContent="center"
          direction="column"
          className={classes.artistNameContainer}
        >
          <Grid item zeroMinWidth>
            <Typography
              align="center"
              gutterBottom={false}
              noWrap
              variant="h5"
              className={classes.artistName}
            >
              {name}
            </Typography>
            {genre && (
              <Typography align="center" noWrap className={classes.genreName}>
                {i18n.language === "lt-LT"
                  ? genre.name
                  : i18n.language === "en-US"
                  ? genre.name_en
                  : genre.name_ua}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container className={classes.contentContainer}>
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            className={classes.imageContainer}
            spacing={2}
          >
            <Grid item zeroMinWidth>
              {image && image.url ? (
                <div
                  style={{
                    backgroundImage: `url(${process.env.GATSBY_API_URL}/${image.url})`,
                  }}
                  className={classes.artistImage}
                />
              ) : (
                <GMDYellow width={150} height={150} />
              )}
              {website && (
                <Link
                  color="secondary"
                  href={website}
                  target="_blank"
                  underline="always"
                  variant="h6"
                  className={classes.artistLink}
                >
                  {website}
                </Link>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            className={classes.textContainer}
            justifyContent="flex-start"
          >
            <Grid item zeroMinWidth>
              <Typography className={classes.infoLabel}>
                {t("map.performance")}:
              </Typography>
              <Typography>
                {`${location.name} (${location.zone.name}) ${time}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {about && (
          <Grid
            container
            direction="column"
            className={classes.aboutTextContainer}
          >
            <Typography className={classes.infoLabel}>
              {t("map.description")}:
            </Typography>
            <Description
              style={{
                minHeight: 0,
                flexBasis: 0,
              }}
            >
              {about}
            </Description>
          </Grid>
        )}
      </Grid>
      <Box>
        <Divider
          style={{
            height: "2px",
          }}
        />
        <ArtistBar onClose={onClose} onShowMap={onShowMap} />
        <Divider
          style={{
            height: "2px",
          }}
        />
      </Box>
    </Box>
  )
}
