import React from "react"
import { Box, Button, Grid, makeStyles } from "@material-ui/core"
import { Close, ChevronLeft, ChevronRight } from "@material-ui/icons"
import { useTranslation } from "react-i18next"

const useLocationBarStyles = makeStyles(theme => {
  return {
    locationBarContainer: {
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    locationName: {
      fontWeight: theme.typography.fontWeightBold,
      textDecoration: "underline",
    },
    locationZone: {
      fontWeight: theme.typography.fontWeightMedium,
      textDecoration: "underline",
    },
    outlinedButton: {
      fontWeight: theme.typography.fontWeightBold,
      borderColor: theme.palette.text.primary,
      height: "100%",
      width: "100%",
      lineHeight: 1.2,
      textTransform: "uppercase",
    },
  }
})

export const LocationBar = ({
  locationName,
  locationZone,
  isListViewActive,
  onToggleListView,
  onToggleMapView,
  onNavigate,
  onResetLocation,
}: {
  locationName: string
  locationZone: string
  isListViewActive: boolean
  onToggleListView: () => void
  onToggleMapView: () => void
  onNavigate: () => void
  onResetLocation: () => void
}) => {
  const classes = useLocationBarStyles()
  const { t } = useTranslation()
  return (
    <Box p={2} className={classes.locationBarContainer}>
      <Grid container spacing={2} justifyContent="space-around">
        <Grid item xs={12} container direction="column" onClick={onNavigate}>
          <span className={classes.locationName}>{locationName}</span>
          <span className={classes.locationZone}>{locationZone}</span>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={() => {
              onResetLocation()
            }}
            size="large"
            className={classes.outlinedButton}
            variant="outlined"
            endIcon={<Close />}
          >
            {t("button.no-filter")}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            size="large"
            endIcon={isListViewActive ? <ChevronRight /> : <ChevronLeft />}
            onClick={() => {
              if (isListViewActive) {
                onToggleMapView()
              } else {
                onToggleListView()
              }
            }}
            className={classes.outlinedButton}
            variant="outlined"
          >
            {isListViewActive ? t("button.map") : t("button.artists")}
          </Button>
        </Grid>
        {/* <Grid item xs={4}>
            <Button
              onClick={onNavigate}
              className={classes.outlinedButton}
              variant='outlined'
              endIcon={<Explore/>}
              >
                <div>Kelias</div>
              </Button>
          </Grid> */}
      </Grid>
    </Box>
  )
}
