import React from "react"
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Card,
  Divider,
  CardMedia,
  CardActionArea,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { Add } from "@material-ui/icons"
import clsx from "clsx"
import { useQueryVisibleCities } from "components/admin/common/city-selector"
import { decimalHourToSexagesimal } from "utilities/decimalHourToSexagesimalHour"
import { GMDMain } from "components/logos/gmd-main-transparent"
import { LocationBar } from "./LocationBar"
import { useTranslation } from "react-i18next"
import { CircularProgress } from "@material-ui/core"
import { Performance } from "./types"

const useStyles = makeStyles(theme => ({
  cityItem: {
    background: theme.palette.primary.main,
  },
  cityItemText: {
    textTransform: "uppercase",
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h4.fontSize,
  },
  cityChoiceText: {
    textTransform: "uppercase",
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h5.fontSize,
  },
  cityListContainer: {
    display: "flex",
    flexDirection: "column",
    flexBasis: 0,
    minHeight: 0,
    overflow: "auto",
    flexGrow: 1,
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    marginRight: theme.spacing(1),
    background: theme.palette.background.default,
    width: "100%",
  },
}))

const CityList = (props: { onSelectCity: (cityId: any) => void }) => {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const { data: cityData, loading } = useQueryVisibleCities()
  const cities =
    cityData &&
    cityData.cities.map(({ id, name, hourFrom, hourTo }) => ({
      id,
      name,
      hourFrom,
      hourTo,
    }))
  return (
    <>
      <ListItem dense className={classes.cityItem}>
        <ListItemText
          primary={t("map.choose-city")}
          style={{
            textAlign: "center",
          }}
          primaryTypographyProps={{
            className: classes.cityChoiceText,
          }}
        />
      </ListItem>
      <List className={classes.cityListContainer} disablePadding>
        {loading && (
          <Grid
            style={{
              margin: "auto",
            }}
            container
            justifyContent="center"
          >
            <CircularProgress color="secondary" size={60} />
          </Grid>
        )}
        {cities &&
          cities.map(({ id, name, hourFrom, hourTo }) => {
            return (
              <div key={id}>
                <ListItem
                  dense
                  button
                  className={classes.cityItem}
                  onClick={() => {
                    props.onSelectCity(id)
                  }}
                >
                  <ListItemText
                    primary={name}
                    style={{
                      textAlign: "center",
                    }}
                    primaryTypographyProps={{
                      className: classes.cityItemText,
                    }}
                  />
                </ListItem>
                <Divider
                  style={{
                    height: "3px",
                  }}
                />
              </div>
            )
          })}
      </List>
    </>
  )
}

const useCardStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "100%",
    background: "white",
  },
  content: {
    flex: "1 0 auto",
    padding: theme.spacing(1),
    minWidth: 0,
    flexBasis: 0,
  },
  cover: {
    width: "31%",
    backgroundColor: theme.palette.primary.main,
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
  },
}))

const ArtistCard = (props: {
  name: string
  time: string
  location: Location
  image: string
  selected: boolean
}) => {
  const classes = useCardStyles()
  const { name, time, location, image, selected } = props
  return (
    <Card
      elevation={2}
      className={clsx(classes.root, {
        [classes.selected]: selected,
      })}
    >
      <CardActionArea
        style={{
          display: "flex",
          alignItems: "stretch",
        }}
      >
        <div className={classes.cover}>
          {image ? (
            <CardMedia
              style={{
                height: "100%",
              }}
              image={image}
              title={name}
            />
          ) : (
            <Grid
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <GMDMain width="80%" height="100%" />
            </Grid>
          )}
        </div>

        <div className={classes.content}>
          <Grid container item zeroMinWidth direction="column">
            <Grid item zeroMinWidth>
              <Typography
                component="h5"
                variant="h5"
                noWrap
                style={{ width: 210 }}
              >
                {name}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {time}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {location}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </CardActionArea>
    </Card>
  )
}

const useArtistListStyles = makeStyles(theme => ({
  listContainer: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    flexBasis: 0,
    flexGrow: 1,
  },
  locationFilterContainer: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.primary.main,
    zIndex: 2,
    border: `solid ${theme.palette.secondary.main} 2px`,
    borderRadius: "4px",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  locationLabel: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  locationName: {
    textAlign: "bottom",
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const ArtistList = (props: {
  performances: Performance[]
  selectedPerformance: Performance
  selectedLocationName: string
  isPreloaderShown: boolean
  onResetLocation: () => void
  onSelectPerformance: (performanceId: any) => void
}) => {
  const {
    performances = [],
    selectedPerformance,
    selectedLocationName,
    isPreloaderShown,
    onResetLocation,
  } = props
  const classes = useArtistListStyles()
  const { t } = useTranslation()
  return (
    <List className={classes.listContainer} disablePadding>
      {selectedLocationName && (
        <Grid
          container
          justifyContent="space-between"
          className={classes.locationFilterContainer}
        >
          <Grid xs={6} item container>
            <Grid item className={classes.locationLabel}>
              {t("admin.location")} &nbsp;
            </Grid>
            <Grid item className={classes.locationName}>
              {selectedLocationName}
            </Grid>
          </Grid>
          <Grid xs={6} item container justifyContent="flex-end">
            <Button
              startIcon={<Add />}
              style={{ fontWeight: "bold" }}
              onClick={onResetLocation}
              variant="outlined"
            >
              {t("map.show-all")}
            </Button>
          </Grid>
        </Grid>
      )}

      {isPreloaderShown ? (
        <Grid
          style={{
            margin: "auto",
          }}
          container
          justifyContent="center"
        >
          <CircularProgress color="secondary" size={60} />
        </Grid>
      ) : performances.length > 0 ? (
        performances.map(performance => {
          const time = `${decimalHourToSexagesimal(
            performance.hourFrom
          )} - ${decimalHourToSexagesimal(performance.hourTo)}`
          return (
            <ListItem
              style={{
                flexShrink: 0, // fixes an issue in Safari where items are resized to fit container
              }}
              onClick={() => {
                props.onSelectPerformance(performance.id)
              }}
              key={performance.id}
              dense
              disableGutters
            >
              <ArtistCard
                selected={
                  selectedPerformance &&
                  selectedPerformance.id === performance.id
                }
                time={time}
                name={performance.performer.name}
                location={performance.location.name}
                image={
                  performance.performer.image &&
                  `${
                    process.env.GATSBY_API_URL
                  }${performance.performer.image.url.replace("large", "thumb")}` // we're using strapi-provider-upload-local-resize which creates different image versions. It doesn't store them in DB, thus the String.replace() call.
                }
              />
            </ListItem>
          )
        })
      ) : (
        <Typography style={{ margin: "auto" }} align="center">
          {t("map.no-performances")}
        </Typography>
      )}
    </List>
  )
}

export const ListView = (props: {
  isCityListShown: boolean
  isPerformancesFetching: boolean
  selectedPerformance: Performance
  performances: Performance[]
  selectedLocationName: string
  selectedZoneName: string
  onNavigate: () => void
  onResetLocation: () => void
  onSelectCity: (cityId: any) => void
  onSelectPerformance: (performanceId: any) => void
  onToggleMapView: () => void
}) => {
  const classes = useStyles(props)
  return (
    <div className={classes.listContainer}>
      {props.isCityListShown ? (
        <CityList onSelectCity={props.onSelectCity} />
      ) : (
        <ArtistList
          isPreloaderShown={props.isPerformancesFetching}
          selectedPerformance={props.selectedPerformance}
          selectedLocationName={props.selectedLocationName}
          performances={props.performances}
          onResetLocation={props.onResetLocation}
          onSelectPerformance={props.onSelectPerformance}
        />
      )}
      {!props.isCityListShown && props.selectedLocationName && (
        <LocationBar
          locationName={props.selectedLocationName}
          locationZone={props.selectedZoneName}
          isListViewActive={true}
          onResetLocation={props.onResetLocation}
          onNavigate={props.onNavigate}
          onToggleListView={() => {
            // unused
          }}
          onToggleMapView={() => {
            props.onToggleMapView()
          }}
        />
      )}
    </div>
  )
}
